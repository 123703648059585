/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import Plyr from 'plyr-react'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Icon from '../Icon'
import icons from '../Icon/assets'
import Image from '../Image'
import A from '../A'
import { durationToMinutes } from '../../helpers/DateHelpers'
import { safeCall } from '../../helpers/React'
import { useDelayedCallback } from '../../utils/hooks/useDelayedCallback'
import routes, { Router } from '../../routes'
import { shareLinksTransformer } from '../../transformers'
import { useIsMobile } from '../../utils/hooks/useWindowSize'

import styles from './styles'


const useStyles = createUseStyles(styles)

const VideoPlayer = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    path,
    theme,
    duration,
    logo,
    videoSource,
    customPlayerOptions,
    linkTranscription,
    speeds, // in case we need to display controls inside push but should not be necessary
    isSmall, // to see all controls when the player is not in fullscreen, true
    isInPush, // various "static texts"
    textPlay,
    textTranscription,
    textOpen,
    textSubtitles,
    textSpeed, // next / prev
    prevSource,
    nextSource,
    nextVideoHandler,
    prevVideoHandler,
  } = props

  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])
  const { t } = useTranslation()

  // state / refs
  const playerContainerRef = useRef(null)
  const nextRef = useRef(null)
  const $hideControlsTimeout = useRef(null)
  const [isFullScreen, setFullScreen] = useState(false)
  const [isTouched, setTouched] = useState(false)
  const [isSettingsOn, setSettingsOn] = useState(false)
  const [isShareFromButtonOn, setShareFromButtonOn] = useState(false)
  const [isShareFromLayerOn, setShareFromLayerOn] = useState(false)
  const [isEnding, setIsEnding] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isSubtitlesOn, setIsSubtitlesOn] = useState(false)
  const [isWithControls, setIsWithControls] = useState(true)
  const [playerInstance, setPlayerInstance] = useState(null)
  const [currentVolume, setCurrentVolume] = useState(0.5)
  const [currentSpeed, setCurrentSpeed] = useState(null)
  const [currentAdvanced, setCurrentAdvanced] = useState(0)
  const [nextDuration, setNextDuration] = useState(null)
  const [tooltipTarget, setTooltipTarget] = useState('')
  const isMobile = useIsMobile()
  const tracker = useRef(null)

  const splitPath = path.split('/')

  const shareLinks = shareLinksTransformer({
    title,
    url: Router.getRouteUrl(routes.shareVideo, { slug: splitPath[splitPath.length - 1], locale: 'fr' }),
  }, t)

  const initTracker = useCallback(() => {
    if (typeof window !== 'undefined' && window.ATInternet) {
      const ATTag = new window.ATInternet.Tracker.Tag()

      tracker.current = ATTag.richMedia
    }
  }, [])

  const trackPayload = useCallback((payload, type) => {
    if (tracker.current) {
      if (type === 'add') {
        tracker.current.add(payload)
      } else if (type === 'send') {
        tracker.current.send(payload)
      }
    } else {
      initTracker()
    }
  }, [initTracker])

  useEffect(() => {
    initTracker()
  }, [initTracker])

  // options
  const definitivePlayerOptions = useMemo(
    () => ({
      // default
      loadSprite: false,
      hideControls: false,
      clickToPlay: true,
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: false,
        container: '.is-plyr-container',
      },
      controls: ['progress', 'current-time'],
      volume: 0.5,
      storage: { enabled: false },
      blankVideo: null,
      // custom
      ...customPlayerOptions,
    }),
    [customPlayerOptions]
  )

  // - init and on videoSource prop change
  useEffect(() => {
    setIsEnding(false)
    setIsWithControls(true)
    setIsPlaying(false)
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
    if (!playerInstance) {
      return
    }
    playerInstance.currentTrack = -1
  }, [videoSource, playerInstance])

  // - play
  const handleTogglePlay = useCallback(() => {
    setTouched(true)
    if (playerInstance) {
      if (isPlaying) {
        clearTimeout($hideControlsTimeout.current)
        setIsWithControls(true)
      }
      playerInstance.togglePlay()
    }
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
  }, [isPlaying, playerInstance])

  // - mute
  const handleMute = useCallback(() => {
    if (!playerInstance) {
      return
    }
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
    if (currentVolume === 0) {
      setCurrentVolume(0.5)
    } else {
      setCurrentVolume(0)
    }
  }, [currentVolume, playerInstance])

  // - handleVolumeControl
  const handleVolumeControl = useCallback((e) => {
    setCurrentVolume((e?.currentTarget?.value ?? 0) / 100)
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
  }, [])

  // - watch volume change
  useEffect(() => {
    if (playerInstance) {
      playerInstance.volume = currentVolume
    }
  }, [currentVolume, playerInstance])

  // - fullscreen toggle
  const handleFullScreen = useCallback(() => {
    if (!playerInstance) {
      return
    }
    const isPlyrFullScreen = playerInstance.fullscreen.active

    if (isPlyrFullScreen) {
      if (isInPush === true) {
        playerInstance.pause()
      }
      playerInstance.fullscreen.exit()
    } else {
      playerInstance.fullscreen.enter()
    }
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
  }, [isInPush, playerInstance])

  const handlePlyrEnterFS = useCallback(() => {
    if (!playerInstance) {
      return
    }
    setFullScreen(true)
    setTouched(true)
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
    if (isSubtitlesOn) {
      playerInstance.currentTrack = 0
    }
  }, [playerInstance, isSubtitlesOn])

  const handlePlyrExitFS = useCallback(() => {
    if (!playerInstance) {
      return
    }
    setFullScreen(false)
    setSettingsOn(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)
    if (playerInstance && isInPush === true) {
      setTouched(false)
      playerInstance.pause()
    }
  }, [isInPush, playerInstance])

  // - start
  const handleStart = useCallback(() => {
    if (isInPush === true) {
      handleFullScreen()
    } else {
      setTouched(true)
    }
    if (playerInstance) {
      playerInstance.play()
    }
  }, [handleFullScreen, isInPush, playerInstance])

  // - settings
  const handleSettings = useCallback(() => {
    setSettingsOn(!isSettingsOn)
    setCurrentAdvanced(0)
  }, [isSettingsOn])

  // - share
  const handleShare = useCallback(() => {
    setShareFromButtonOn(!isShareFromButtonOn)
    setShareFromLayerOn(!isShareFromLayerOn)
  }, [isShareFromButtonOn, isShareFromLayerOn])


  // - speed settings
  const handleAdvancedNav = useCallback((index) => {
    setCurrentAdvanced(index ?? 0)
  }, [])

  const handleLoadStart = useCallback(() => {
    if (!playerInstance) {
      return
    }

    const payload = {
      mediaType: 'video',
      playerId: playerInstance.id,
      mediaTheme1: theme,
      mediaLabel: title,
      refreshDuration: {
        0: 5,
        1: 15,
        5: 30,
        10: 60,
      },
      duration,
      isEmbedded: false,
      broadcastMode: 'clip',
    }

    trackPayload(payload, 'add')
  }, [duration, playerInstance, theme, title, trackPayload])

  // - speed
  useEffect(() => {
    setCurrentSpeed(speeds.find((speed) => speed?.isDefault === true))
  }, [speeds])
  const handleSpeed = useCallback(
    (speed) => {
      setCurrentSpeed(speed)
      if (playerInstance) {
        playerInstance.speed = speed.value ?? 1
      }
      handleSettings()
    },
    [handleSettings, playerInstance]
  )

  // - subtitle77s
  const handleSubtitles = useCallback(
    (e) => {
      if (!playerInstance) {
        return
      }
      if (!isSubtitlesOn) {
        playerInstance.currentTrack = 0
        setIsSubtitlesOn(true)
      } else if (isSubtitlesOn) {
        playerInstance.currentTrack = -1
        setIsSubtitlesOn(false)
      }
    },
    [isSubtitlesOn, playerInstance]
  )

  // next
  const handleNextVideo = useCallback(() => {
    safeCall(nextVideoHandler)
  }, [nextVideoHandler])

  // prev
  const handlePrevVideo = useCallback(() => {
    safeCall(prevVideoHandler)
  }, [prevVideoHandler])

  const handleShareOnFacebook = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.open(shareLinks.links[0].linkProps.href, '_blank')
    }
  }, [shareLinks])

  const handleShareOnTwitter = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.open(shareLinks.links[1].linkProps.href, '_blank')
    }
  }, [shareLinks])

  // renderers

  // tooltip

  const renderTooltip = useCallback((text, custom) => <div className={cx(classes.tooltip, custom)}>{text}</div>, [
    classes.tooltip,
  ])

  // - plyr
  const renderPlayer = useMemo(
    () => (
      <Plyr
        ref={(plyr) => setPlayerInstance(plyr.plyr)}
        source={videoSource}
        options={definitivePlayerOptions}
        poster={definitivePlayerOptions.poster}
        data-poster={definitivePlayerOptions.poster}
        preload="metadata"
      />
    ),
    [definitivePlayerOptions, videoSource]
  )

  const renderShare = useMemo(() => (
    <button
      type="button"
      className={cx(classes.controlButton, classes.controlButtonShare)}
      onClick={isMobile ? handleShare : () => {}}
      onMouseEnter={() => setShareFromButtonOn(true)}
      onMouseLeave={() => {
        setTimeout(() => {
          setShareFromButtonOn(false)
          if (!isShareFromButtonOn && !isShareFromLayerOn) {
            setShareFromLayerOn(false)
          }
        }, 500)
      }}
    >
      <Icon
        icon={icons.ShareReverse}
        className={cx(classes.controlIcon, classes.controlIconShare)}
      />
    </button>
  // eslint-disable-next-line max-len
  ), [classes.controlButton, classes.controlButtonShare, classes.controlIcon, classes.controlIconShare, handleShare, isMobile, isShareFromButtonOn, isShareFromLayerOn])

  // - volume
  const renderVolume = useMemo(
    () => videoSource && (
    <div className={classes.controlVolume}>
      <input
        type="range"
        defaultValue={100 * currentVolume}
        step={1}
        onInput={(e) => handleVolumeControl(e)}
      />
    </div>
      ),
    [classes.controlVolume, currentVolume, handleVolumeControl, videoSource]
  )

  // - controls
  const renderControls = useMemo(
    () => videoSource && (
    <>
      {prevSource && (
      <button
        type="button"
        className={cx(classes.controlButton, classes.controlButtonNav)}
        onClick={handlePrevVideo}
        onMouseEnter={() => setTooltipTarget('previous')}
        onMouseLeave={() => setTooltipTarget('')}
      >
        <Icon
          icon={icons.PlayerPrevious}
          className={classes.controlIcon}
        />
        {tooltipTarget === 'previous' && renderTooltip(t('videoPlayerTooltipPrevious'), classes.tooltipPrevious)}
      </button>
          )}
      <button
        type="button"
        className={cx(classes.controlButton, classes.controlButtonPlay)}
        onClick={handleTogglePlay}
        onMouseEnter={() => setTooltipTarget(isPlaying ? 'pause' : 'play')}
        onMouseLeave={() => setTooltipTarget('')}
      >
        <Icon
          icon={icons.PlayerPause}
          className={cx(classes.controlIcon, classes.controlIconPause)}
        />
        <Icon
          icon={icons.PlayerPlay}
          className={cx(classes.controlIcon, classes.controlIconPlay)}
        />
        {tooltipTarget === 'pause' && renderTooltip(t('videoPlayerTooltipPause'), classes.tooltipPause)}
        {tooltipTarget === 'play' && renderTooltip(t('videoPlayerTooltipPlay'), classes.tooltipPlay)}
      </button>
      {nextSource && (
      <button
        type="button"
        className={cx(classes.controlButton, classes.controlButtonNav)}
        onClick={handleNextVideo}
        onMouseEnter={() => setTooltipTarget('next')}
        onMouseLeave={() => setTooltipTarget('')}
      >
        <Icon
          icon={icons.PlayerNext}
          className={classes.controlIcon}
        />
        {tooltipTarget === 'next' && renderTooltip(t('videoPlayerTooltipNext'), classes.tooltipNext)}
      </button>
          )}
      <div className={classes.controlMenu}>
        <button
          type="button"
          className={cx(classes.controlButton, classes.controlButtonMute)}
          onClick={handleMute}
        >
          {currentVolume === 0 ? (
            <Icon
              icon={icons.PlayerMute}
              className={classes.controlIcon}
            />
              ) : (
                <Icon
                  icon={icons.PlayerSound}
                  className={classes.controlIcon}
                />
              )}
        </button>
        {renderVolume}
      </div>
    </>
      ),
    // eslint-disable-next-line max-len
    [videoSource, prevSource, classes.controlButton, classes.controlButtonNav, classes.controlIcon, classes.tooltipPrevious, classes.controlButtonPlay, classes.controlIconPause, classes.controlIconPlay, classes.tooltipPause, classes.tooltipPlay, classes.tooltipNext, classes.controlMenu, classes.controlButtonMute, handlePrevVideo, tooltipTarget, renderTooltip, t, handleTogglePlay, nextSource, handleNextVideo, handleMute, currentVolume, renderVolume, isPlaying]
  )

  const renderShareLayer = useMemo(() => (
    <div
      className={cx(classes.advanced, classes.advancedShare, (isShareFromButtonOn || isShareFromLayerOn) && 'is-open')}
      onMouseEnter={() => {
        setShareFromLayerOn(true)
      }}
      onMouseLeave={() => {
        setShareFromLayerOn(false)
      }}
    >
      <h4 className={classes.shareTitle}>{t('videoPlayerShareTitle')}</h4>
      <div className={classes.shareButtons}>
        <button
          type="button"
          className={classes.shareLayerButton}
          onClick={handleShareOnFacebook}
        >
          <Image
            src="/images/Common/fb.png"
            alt="Facebook"
            className={classes.shareLayerIcon}
          />
          {t('videoPlayerShareFacebook')}
        </button>

        <button
          type="button"
          className={classes.shareLayerButton}
          onClick={handleShareOnTwitter}
        >
          <Image
            src="/images/Common/twitter.png"
            alt="Twitter"
            className={classes.shareLayerIcon}
          />
          {t('videoPlayerShareTwitter')}
        </button>
      </div>
    </div>
  // eslint-disable-next-line max-len
  ), [classes.advanced, classes.advancedShare, classes.shareButtons, classes.shareLayerButton, classes.shareLayerIcon, classes.shareTitle, handleShareOnFacebook, handleShareOnTwitter, isShareFromButtonOn, isShareFromLayerOn, t])

  // - advanced settings
  const renderAdvancedSettings = useMemo(
    () => videoSource && (
    <div className={cx(classes.advanced, isSettingsOn === true && 'is-open')}>
      <ul className={cx(classes.advancedMain, currentAdvanced === 0 && 'is-visible')}>
        {linkTranscription && linkTranscription.href && (
        <li>
          <b>{textTranscription}</b>
          <A {...linkTranscription}>{textOpen}</A>
        </li>
            )}
        {videoSource && videoSource.tracks && videoSource.tracks.length > 0 && (
        <li>
          <b>{textSubtitles}</b>
          <label>
            <input
              type="checkbox"
              onChange={(e) => handleSubtitles(e)}
              checked={isSubtitlesOn}
            />
            <span>{textSubtitles}</span>
          </label>
        </li>
            )}
        <li>
          <b>{textSpeed}</b>
          <button
            type="button"
            onClick={() => handleAdvancedNav(1)}
          >
            <span>{currentSpeed && currentSpeed?.text}</span>
            <Icon icon={icons.ChevronSquareRight} />
          </button>
        </li>
      </ul>
      <ul className={cx(classes.advancedSpeed, currentAdvanced === 1 && 'is-visible')}>
        <li>
          <button
            type="button"
            onClick={() => handleAdvancedNav(0)}
          >
            <Icon icon={icons.ChevronSquareLeft} />
            <b>{textSpeed}</b>
          </button>
        </li>
        {speeds
              && speeds.map((speed, index) => (
                <li key={`videoplayer-speed-${index}`}>
                  <button
                    type="button"
                    onClick={() => handleSpeed(speed)}
                  >
                    {speed.text}
                  </button>
                </li>
              ))}
      </ul>
    </div>
        // eslint-disable-next-line max-len
      ),
    [
      classes.advanced,
      classes.advancedMain,
      classes.advancedSpeed,
      currentAdvanced,
      currentSpeed,
      handleAdvancedNav,
      handleSpeed,
      handleSubtitles,
      isSettingsOn,
      isSubtitlesOn,
      linkTranscription,
      speeds,
      textOpen,
      textSpeed,
      textSubtitles,
      textTranscription,
      videoSource,
    ]
  )

  // - settings
  const renderSettings = useMemo(
    () => videoSource && (
    <>
      <div className={classes.controlMenu}>
        {renderShare}
        {renderShareLayer}
      </div>
      <div className={classes.controlMenu}>
        <button
          type="button"
          className={cx(classes.controlButton, classes.controlButtonMute)}
          onClick={handleMute}
        >
          {currentVolume > 0 ? (
            <Icon
              icon={icons.PlayerSound}
              className={classes.controlIcon}
            />
              ) : (
                <Icon
                  icon={icons.PlayerMute}
                  className={classes.controlIcon}
                />
              )}
        </button>
        {renderVolume}
      </div>
      <div className={classes.controlMenu}>
        <button
          type="button"
          className={cx(classes.controlButton, classes.controlButtonSettings)}
          onClick={handleSettings}
          onMouseEnter={() => setTooltipTarget('settings')}
          onMouseLeave={() => setTooltipTarget('')}
        >
          <Icon
            icon={icons.PlayerSettings}
            className={classes.controlIcon}
          />
          {tooltipTarget === 'settings' && renderTooltip(t('videoPlayerTooltipSettings'), classes.tooltipSettings)}
        </button>
        {renderAdvancedSettings}
      </div>
      <button
        type="button"
        className={cx(classes.controlButton, classes.controlButtonFullscreen)}
        onClick={handleFullScreen}
        onMouseEnter={() => setTooltipTarget(isFullScreen ? 'exit-fullscreen' : 'fullscreen')}
        onMouseLeave={() => setTooltipTarget('')}
      >
        {isFullScreen ? (
          <Icon
            icon={icons.PlayerNormalScreen}
            className={classes.controlIcon}
          />
            ) : (
              <Icon
                icon={icons.PlayerFullscreen}
                className={classes.controlIcon}
              />
            )}
        {tooltipTarget === 'exit-fullscreen' && renderTooltip(t('videoPlayerTooltipExitFullscreen'), classes.tooltipExitFullscreen)}
        {tooltipTarget === 'fullscreen' && renderTooltip(t('videoPlayerTooltipFullscreen'), classes.tooltipFullscreen)}
      </button>
    </>
      ),
    // eslint-disable-next-line max-len
    [classes.controlButton, classes.controlButtonFullscreen, classes.controlButtonMute, classes.controlButtonSettings, classes.controlIcon, classes.controlMenu, classes.tooltipExitFullscreen, classes.tooltipFullscreen, classes.tooltipSettings, currentVolume, handleFullScreen, handleMute, handleSettings, isFullScreen, renderAdvancedSettings, renderShare, renderShareLayer, renderTooltip, renderVolume, t, tooltipTarget, videoSource]
  )

  // - next thumbnail
  const renderNext = useMemo(
    () => nextSource && (
    <button
      type="button"
      className={cx(classes.next, isEnding === true && 'is-visible')}
      onClick={handleNextVideo}
    >
      <span className={classes.nextTitles}>
        {nextSource.title && <span>{nextSource.title}</span>}
      </span>
      {nextDuration && (
      <span className={classes.nextDuration}>{durationToMinutes(nextDuration)}</span>
          )}
      <Image
        className={classes.nextImage}
        src={nextSource.poster}
      />
    </button>
      ),
    [classes, handleNextVideo, isEnding, nextDuration, nextSource]
  )

  // other effects
  // - fetch the duration of the next video
  const handleFetchDuration = useCallback(() => {
    const nextRefCurrentDuration = nextRef?.current?.duration ?? -1

    if (nextRefCurrentDuration !== -1) {
      setNextDuration(nextRefCurrentDuration)
    }
  }, [])

  // - set the duration in state
  const handleEnding = useCallback(() => {
    if (!playerInstance) {
      return
    }
    const currentTime = playerInstance.currentTime
    const totalTime = playerInstance?.duration ?? 0

    if (isPlaying && totalTime - currentTime <= 6) {
      setIsEnding(true)
    } else {
      setIsEnding(false)
    }
  }, [isPlaying, playerInstance])

  // - if the video is ending, show next
  useEffect(() => {
    if (nextRef.current) {
      nextRef.current.addEventListener('loadeddata', handleFetchDuration)
    }
  }, [handleFetchDuration, nextSource])

  // - if the video has ended cycle to next
  const handleEnded = useCallback(() => {
    handleNextVideo()
    setIsPlaying(false)
    setIsWithControls(true)
  }, [handleNextVideo])

  // - when the video starts playing hide the controls
  const delayedHandlePlaying = useDelayedCallback(2500)

  const handlePlaying = useCallback(() => {
    setIsPlaying(true)
    delayedHandlePlaying(() => {
      setIsWithControls(false)
    })
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)

    const payload = {
      action: 'play',
      playerId: playerInstance?.id,
      mediaTheme1: theme,
      mediaLabel: title,
    }

    trackPayload(payload, 'send')
  }, [delayedHandlePlaying, playerInstance, theme, title, trackPayload])

  // - handle pause
  const handlePause = useCallback(() => {
    setIsPlaying(false)
    setShareFromButtonOn(false)
    setShareFromLayerOn(false)

    const payload = {
      action: 'pause',
      playerId: playerInstance?.id,
      mediaTheme1: theme,
      mediaLabel: title,
    }

    trackPayload(payload, 'send')
  }, [playerInstance, theme, title, trackPayload])

  // - reveal controls on click
  // const handleShowControls = useCallback((event) => {
  //   event.stopPropagation()
  //   setIsWithControls(true)
  // }, [])

  // - reveal controls on click
  const handleHideControls = useCallback(
    (event) => {
      event.stopPropagation()

      if (!isPlaying) {
        setIsWithControls(true)
      }
      if (!isWithControls && isPlaying) {
        event.stopPropagation()
        setIsWithControls(true)
        clearTimeout($hideControlsTimeout.current)
        $hideControlsTimeout.current = setTimeout(() => {
          setIsWithControls(false)
        }, 2500)
      }
    },
    [isPlaying, isWithControls]
  )

  // - events triggered while listening to the player
  useEffect(() => {
    if (!playerInstance) {
      return
    }
    playerInstance.on('playing', handlePlaying)
    playerInstance.on('pause', handlePause)
    playerInstance.on('timeupdate', handleEnding)
    playerInstance.on('ended', handleEnded)
    playerInstance.on('enterfullscreen', handlePlyrEnterFS)
    playerInstance.on('exitfullscreen', handlePlyrExitFS)
    playerInstance.on('loadstart', handleLoadStart)
    return () => {
      playerInstance.off('playing', handlePlaying)
      playerInstance.off('pause', handlePause)
      playerInstance.off('timeupdate', handleEnding)
      playerInstance.off('ended', handleEnded)
      playerInstance.off('enterfullscreen', handlePlyrEnterFS)
      playerInstance.off('exitfullscreen', handlePlyrExitFS)
    }
  }, [
    handleEnded,
    handleEnding,
    handleLoadStart,
    handlePause,
    handlePlaying,
    handlePlyrEnterFS,
    handlePlyrExitFS,
    playerInstance,
  ])

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx(
        classes.container,
        className,
        isInPush === true ? 'is-inpush' : 'is-notinpush',
        isTouched === true ? 'is-touched' : 'is-untouched',
        isSmall === true ? 'is-small' : 'is-big',
        isPlaying === true ? 'is-playing' : 'is-paused',
        isWithControls === true ? 'is-withcontrols' : 'is-withoutcontrols',
        isFullScreen ? 'is-fullscreen' : 'is-notfullscreen'
      )}
      onMouseMove={(e) => handleHideControls(e)}
    >
      <div
        className={cx(classes.wrapper, 'is-plyr-container')}
        ref={playerContainerRef}
      >
        <div className={cx(classes.player)}>
          {renderPlayer}
          <button
            type="button"
            className={classes.playscreen}
            onClick={handleStart}
          >
            <i>{textPlay}</i>
            <span className={classes.playscreenControl}>
              <Icon
                icon={icons.Play}
                className={classes.playscreenIcon}
              />
            </span>
          </button>
          {renderNext}
          <div className={classes.playerUI}>
            <div className={classes.bottomUI}>
              <div className={classes.controls}>{renderControls}</div>
              {title && (
                <div className={classes.titles}>
                  {title && <span className={classes.title}>{title}</span>}
                </div>
              )}
              <div className={classes.settings}>{renderSettings}</div>
            </div>
          </div>
          {logo && (
            <Image
              {...logo}
              className={classes.logo}
            />
          )}
        </div>
        {nextSource && nextSource.src && (
          <video
            style={{ display: 'none' }}
            ref={nextRef}
          >
            <source
              src={nextSource.src}
              type={nextSource.type}
            />
          </video>
        )}
      </div>
    </div>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  isSmall: PropTypes.bool,
  isInPush: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  theme: PropTypes.string,
  duration: PropTypes.number,
  textPlay: PropTypes.string,
  logo: PropTypes.shape(Image.propTypes),
  videoSource: PropTypes.shape({
    type: PropTypes.string,
    poster: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.object),
    tracks: PropTypes.arrayOf(
      PropTypes.shape({
        kind: PropTypes.string,
        label: PropTypes.string,
        srclang: PropTypes.string,
        src: PropTypes.string,
      })
    ),
  }),
  linkTranscription: PropTypes.shape(A.propTypes),
  customPlayerOptions: PropTypes.arrayOf(PropTypes.object),
  speeds: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
      isDefault: PropTypes.bool,
    })
  ),
  textTranscription: PropTypes.string,
  textOpen: PropTypes.string,
  textSubtitles: PropTypes.string,
  textSpeed: PropTypes.string,
  prevSource: PropTypes.shape({
    title: PropTypes.string,
    poster: PropTypes.string,
    video: PropTypes.string,
  }),
  nextSource: PropTypes.shape({
    title: PropTypes.string,
    poster: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.string,
  }),
  nextVideoHandler: PropTypes.func,
  prevVideoHandler: PropTypes.func,
}

VideoPlayer.defaultProps = {
  className: null,
  classes: null,
  isSmall: false,
  isInPush: false,
  title: null,
  path: null,
  theme: null,
  duration: 0,
  textPlay: null,
  logo: null,
  videoSource: null,
  linkTranscription: null,
  customPlayerOptions: null,
  speeds: [],
  textTranscription: null,
  textOpen: null,
  textSubtitles: null,
  textSpeed: null,
  prevSource: null,
  nextSource: null,
  nextVideoHandler: () => null,
  prevVideoHandler: () => null,
}

export default withMemo()(VideoPlayer)
